<template>
  <div>
    <PreLoader v-if="isLoading" />
    <div v-if="usersData" class="table-container">
      <!-- header info -->
      <div class="row">
        <DataLabelGroup
          :className="'col-lg-5 col-md-4'"
          :value="
            educationalScheduleTime.educationalGroupInfoData
              .educationalGroupNameCurrent
          "
          :title="$t('EducationalGroups.name')"
          :imgName="'EducationalGroups.svg'"
        />
        <DataLabelGroup
          :className="'col-lg-5 col-md-4'"
          :value="educationalScheduleTime.placeInfoData.placeNameCurrent"
          :title="$t('Places.name')"
          :imgName="'places.svg'"
        />
        <DataLabelGroup
          :className="'col-lg-2 col-md-4'"
          :value="educationalScheduleTime.appointmentTypeNameCurrent"
          :title="$t('ConstantsListSelect.AppointmentTypesName')"
          :imgName="'AppointmentTypes.svg'"
        />

        <DateTimePicker
          class="col-lg-5 col-md-4"
          :id="`student-attendFromDateTime`"
          type="dateTime"
          :value="educationalScheduleTime.dateTimeFromCustomized"
          :title="$t('from')"
          v-on:changeValue="
            educationalScheduleTime.dateTimeFromCustomized = $event.dateTime
          "
          :language="language"
        />
        <DateTimePicker
          class="col-lg-5 col-md-4"
          :id="`student-attendToDateTime`"
          type="dateTime"
          :value="educationalScheduleTime.dateTimeToCustomized"
          :title="$t('to')"
          v-on:changeValue="
            educationalScheduleTime.dateTimeToCustomized = $event.dateTime
          "
          :language="language"
        />
        <!-- this -->
        <!-- <button
          :disabled="!hasDataSelected"
          name="submit"
          type="submit"
          class="col-lg-2 col-md-4 btn btn-submit"
          @click.prevent="addEducationalGroupAttendance"
        >
          {{ $t("EducationalGroupAttendances.attend") }}
        </button> -->
      </div>

      <!-- search -->
      <!-- this -->
      <!-- <div class="row">
        <CustomInput
          :className="'col-md-12'"
          :id="'filterTable'"
          :value="filterTable"
          :title="$t('search')"
          :imgName="'search.svg'"
          v-on:changeValue="filterTable = $event"
        />
      </div> -->
      <!-- ======= -->
      <div
        class="show-table-attend btn btn-info mb-2"
        @click="showTable = !showTable"
      >
        <p v-show="!showTable" class="text-white">
          {{ $t("show") }} {{ $t("table") }}
        </p>
        <p v-show="showTable" class="text-white">
          {{ $t("hide") }} {{ $t("table") }}
        </p>
      </div>
      <!-- ======= -->
      <!-- main Table -->

      <table v-show="showTable" class="my-table">
        <thead>
          <tr>
            <th rowspan="2">#</th>
            <th rowspan="2">
              <input
                v-if="hasDataFiltered"
                type="checkbox"
                id="selectAll"
                @click="selectAll('attendance')"
                v-model="attendanceSelectedAll"
                class="checkbox"
              />
              {{ $t("EducationalGroupAttendances.attendance") }}
            </th>
            <th rowspan="2">
              <input
                v-if="hasDataFiltered"
                type="checkbox"
                id="selectAll"
                @click="selectAll('absence')"
                v-model="absenceSelectedAll"
                class="checkbox"
              />
              {{ $t("EducationalGroupAttendances.absence") }}
            </th>
            <th rowspan="2">
              {{ $t("ConstantsListSelect.AttendanceTypesName") }}
            </th>
            <th colspan="2">{{ $t("students.data") }}</th>
            <th colspan="2">
              {{ $t("JoiningApplicationSubscriptions.data") }}
            </th>
            <th rowspan="2" colspan="3">
              {{ $t("EducationalScheduleUsers.lastEducationalScheduleData") }}
            </th>
            <th rowspan="2">
              {{ $t("EducationalScheduleUsers.countWarning") }}
            </th>
            <th colspan="3">{{ $t("Debts.data") }}</th>
            <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
          </tr>
          <tr>
            <th>{{ $t("general.code") }}</th>
            <th>{{ $t("general.name") }}</th>
            <th>{{ $t("JoiningApplicationSubscriptions.status") }}</th>
            <th>
              {{ $t("JoiningApplicationSubscriptions.subscriptionToDateTime") }}
            </th>
            <th>{{ $t("totalMoney") }}</th>
            <th>{{ $t("paidMoney") }}</th>
            <th>{{ $t("remainingAmount") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in searchFilteredUsersData" :key="index">
            <td
              class="cell-color"
              :style="{
                backgroundColor: user.studentStatusColorHex,
              }"
            >
              {{ ++index }}
            </td>
            <td>
              <input
                v-if="
                  user.currentEducationalScheduleData.attendanceTypeToken ==
                    ATTENDANCE_TYPE.Unknown &&
                  checkPrivilege(hasEducationalGroupAttendanceAdd()) &&
                  user.subscriptionData.canAttendaceStatus
                "
                type="checkbox"
                v-model="attendanceTokens"
                :value="user.userToken"
                @click="select('attendance', user.userToken)"
                class="checkbox"
              />
              <span v-else>-</span>
            </td>
            <td>
              <input
                v-if="
                  user.currentEducationalScheduleData.attendanceTypeToken ==
                    ATTENDANCE_TYPE.Unknown &&
                  checkPrivilege(hasEducationalGroupAttendanceAdd()) &&
                  user.subscriptionData.canAttendaceStatus
                "
                type="checkbox"
                v-model="absenceTokens"
                :value="user.userToken"
                @click="select('absence', user.userToken)"
                class="checkbox"
              />
              <span v-else>-</span>
            </td>
            <td>
              <img
                class="item-img-table-sm"
                :src="
                  getAttendanceIcon(
                    user.currentEducationalScheduleData.attendanceTypeToken
                  )
                "
                :title="
                  user.currentEducationalScheduleData.attendanceTypeNameCurrent
                "
              />
            </td>
            <td>
              {{ isDataExist(user.fullCode) }}
            </td>
            <td>
              {{ isDataExist(user.userNameCurrent) }}
            </td>
            <td>
              {{
                user.subscriptionData.canAttendaceStatus
                  ? $t("JoiningApplicationSubscriptions.valid")
                  : $t("JoiningApplicationSubscriptions.notValid")
              }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  user.subscriptionData.subscriptionToDate,
                  user.subscriptionData.subscriptionToTime
                )
              }}
            </td>
            <template v-if="user.lastEducationalScheduleData.length">
              <td v-if="user.lastEducationalScheduleData[0]">
                <img
                  class="item-img-table-sm"
                  :src="
                    getAttendanceIcon(
                      user.lastEducationalScheduleData[0].attendanceTypeToken
                    )
                  "
                  :title="`
                ${formateDateTimeLang(
                  user.lastEducationalScheduleData[0].attendFromDate,
                  user.lastEducationalScheduleData[0].attendFromTime
                )} -- ${formateDateTimeLang(
                    user.lastEducationalScheduleData[0].attendFromDate,
                    user.lastEducationalScheduleData[0].attendFromTime
                  )}
              `"
                />
              </td>
              <td v-else>-</td>
              <td v-if="user.lastEducationalScheduleData[1]">
                <img
                  class="item-img-table-sm"
                  :src="
                    getAttendanceIcon(
                      user.lastEducationalScheduleData[1].attendanceTypeToken
                    )
                  "
                  :title="`
                ${formateDateTimeLang(
                  user.lastEducationalScheduleData[1].attendFromDate,
                  user.lastEducationalScheduleData[1].attendFromTime
                )} -- ${formateDateTimeLang(
                    user.lastEducationalScheduleData[1].attendFromDate,
                    user.lastEducationalScheduleData[1].attendFromTime
                  )}
              `"
                />
              </td>
              <td v-else>-</td>
              <td v-if="user.lastEducationalScheduleData[2]">
                <img
                  class="item-img-table-sm"
                  :src="
                    getAttendanceIcon(
                      user.lastEducationalScheduleData[2].attendanceTypeToken
                    )
                  "
                  :title="`
                ${formateDateTimeLang(
                  user.lastEducationalScheduleData[2].attendFromDate,
                  user.lastEducationalScheduleData[2].attendFromTime
                )} -- ${formateDateTimeLang(
                    user.lastEducationalScheduleData[2].attendFromDate,
                    user.lastEducationalScheduleData[2].attendFromTime
                  )}
              `"
                />
              </td>
              <td v-else>-</td>
            </template>
            <template v-else>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </template>
            <td>
              {{
                isDataExist(
                  user.countWarningText
                    .split("")
                    .reduce((acc, char) => char + acc, "")
                )
              }}
            </td>
            <template v-if="user.userDebtInfo.hasData">
              <td>{{ user.userDebtInfo.debtMoney }}</td>
              <td>{{ user.userDebtInfo.paidMoney }}</td>
              <td>{{ user.userDebtInfo.remainderMoney }}</td>
            </template>
            <template v-else>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </template>
            <td class="fmenu-item-container">
              <FloatingMenu>
                <li v-if="checkPrivilege(hasUpdateWarning())">
                  <button
                    @click="
                      setEducationalGroupUpdateWarningData(user);
                      openBottomSheet('EducationalGroupUpdateWarning');
                    "
                    :title="$t('EducationalGroups.updateWarning')"
                  >
                    <img src="@/assets/images/alert.svg" />
                  </button>
                </li>
                <li
                  v-if="
                    checkPrivilege(hasJoiningApplicationSubscriptionAdd()) &&
                    !user.subscriptionData.canAttendaceStatus
                  "
                >
                  <button
                    @click="
                      setJoiningApplicationSubscriptionData(user);
                      openBottomSheet('JoiningApplicationSubscriptionAdd');
                    "
                    :title="$t('JoiningApplicationSubscriptions.add')"
                  >
                    <img
                      src="@/assets/images/JoiningApplicationSubscriptions.svg"
                    />
                  </button>
                </li>
                <li
                  v-if="checkPrivilege(hasDebt()) && user.userDebtInfo.hasData"
                >
                  <button
                    @click="
                      setSelectedSubscriptionDebtsNotFinishedData(user);
                      openBottomSheet('UserDebtInfo');
                    "
                    :title="$t('Debts.data')"
                  >
                    <img src="@/assets/images/debts.svg" />
                  </button>
                </li>
                <li
                  v-if="
                    checkPrivilege(
                      hasEducationalGroupAttendanceFinaleDelete()
                    ) &&
                    user.currentEducationalScheduleData.attendanceTypeToken !=
                      ATTENDANCE_TYPE.Unknown
                  "
                >
                  <button
                    v-b-modal.EducationalGroupAttendanceDelete
                    :title="$t('delete')"
                    @click="setEducationalGroupAttendanceData(user)"
                  >
                    <img src="@/assets/images/trash.svg" />
                  </button>
                </li>
              </FloatingMenu>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- ============magdy -->
      <div class="">
        <!-- search -->
        <div class="row mt-4">
          <CustomInput
            :className="'col-md-12'"
            :id="'filterTable'"
            :value="filterTable"
            :title="$t('search')"
            :imgName="'search.svg'"
            v-on:changeValue="filterTable = $event"
          />
        </div>
        <button
          :disabled="!hasDataSelected"
          name="submit"
          type="submit"
          class="col-lg-2 col-md-4 btn btn-submit"
          @click.prevent="addEducationalGroupAttendance"
        >
          {{ $t("EducationalGroupAttendances.attend") }}
        </button>
      </div>
      <!-- ============magdy End -->
      <!-- selected Table -->
      <div class="my-card mt-5">
        <span class="my-card-title">{{
          $t("EducationalScheduleUsers.studentsToAttendance")
        }}</span>
        <table v-if="selectedUsersData.length" class="my-table">
          <thead>
            <tr>
              <th rowspan="2">#</th>
              <th rowspan="2">
                {{ $t("delete") }}
              </th>
              <th rowspan="2">
                {{ $t("EducationalGroupAttendances.attendance") }}
              </th>
              <th rowspan="2">
                {{ $t("EducationalGroupAttendances.absence") }}
              </th>
              <th colspan="2">{{ $t("students.data") }}</th>
            </tr>
            <tr>
              <th>{{ $t("general.code") }}</th>
              <th>{{ $t("general.name") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in selectedUsersData" :key="index">
              <td>{{ ++index }}</td>
              <td>
                <button @click="removeUserFromSelectedList(user)">
                  <img src="@/assets/images/trash.svg" />
                </button>
              </td>
              <td>
                <input
                  v-if="
                    user.currentEducationalScheduleData.attendanceTypeToken ==
                      ATTENDANCE_TYPE.Unknown &&
                    checkPrivilege(hasEducationalGroupAttendanceAdd())
                  "
                  type="checkbox"
                  v-model="attendanceTokens"
                  :value="user.userToken"
                  @click="select('attendance', user.userToken)"
                  class="checkbox"
                />
                <span v-else>-</span>
              </td>
              <td>
                <input
                  v-if="
                    user.currentEducationalScheduleData.attendanceTypeToken ==
                      ATTENDANCE_TYPE.Unknown &&
                    checkPrivilege(hasEducationalGroupAttendanceAdd())
                  "
                  type="checkbox"
                  v-model="absenceTokens"
                  :value="user.userToken"
                  @click="select('absence', user.userToken)"
                  class="checkbox"
                />
                <span v-else>-</span>
              </td>
              <td>
                {{ isDataExist(user.fullCode) }}
              </td>
              <td>
                {{ isDataExist(user.userNameCurrent) }}
              </td>
            </tr>
          </tbody>
        </table>
        <span v-else>{{ $t("EducationalScheduleUsers.pleaseSelectOne") }}</span>
        <span id="section-to-scroll-to">.</span>
      </div>
    </div>
  </div>
</template>

<script>
import PreLoader from "./../../../../components/general/PreLoader.vue";
import DataLabelGroup from "./../../../../components/general/DataLabelGroup.vue";
import DateTimePicker from "./../../../../components/general/DateTimePicker.vue";
import CustomInput from "./../../../../components/general/CustomInput.vue";
import { STATUS } from "./../../../../utils/constants";
import {
  getLanguage,
  checkPrivilege,
  isDataExist,
  formateDateTimeLang,
} from "./../../../../utils/functions";
import EducationalGroupAttendanceModel from "./../../../../models/educational/educationalGroupAttendances/EducationalGroupAttendanceModel";
import apiEducationalGroupAttendance from "./../../../../api/educational/educationalGroupAttendances";
import {
  hasEducationalGroupAttendanceAdd,
  hasJoiningApplicationSubscriptionAdd,
  hasUpdateWarning,
  hasDebt,
  hasEducationalGroupAttendanceFinaleDelete,
  hasEducationalScheduleTimeEdit,
} from "./../../../../utils/privilegeHelper";
import FloatingMenu from "./../../../../components/general/FloatingMenu.vue";
import generalMixin from "./../../../../utils/generalMixin";
import {
  ATTENDANCE_TYPE,
  SUBSCRIPTION_TYPE_TOKENS,
} from "./../../../../utils/constantLists";
import unknownIcon from "@/assets/images/question.svg";
import attendanceIcon from "@/assets/images/check-icon.svg";
import absenceIcon from "@/assets/images/close.svg";

export default {
  name: "EducationalScheduleUsersTable",
  // add by magdy ==========
  updated() {
    this.scrollToSection();
  },
  mounted() {
    this.scrollToSection();
    const inputElement = document.getElementById("filterTable");
    if (inputElement) {
      inputElement.focus(); // Focus on the input element when the component is mounted
    }
  },
  // added End ==========
  mixins: [generalMixin],
  components: {
    PreLoader,
    DataLabelGroup,
    DateTimePicker,
    CustomInput,
    FloatingMenu,
  },
  data() {
    return {
      language: getLanguage(),
      attendFromDateTime: "",
      attendToDateTime: "",
      attendanceSelectedAll: false,
      attendanceTokens: [],
      absenceSelectedAll: false,
      absenceTokens: [],
      filterTable: "",
      filteredUsersData: [],
      usersDataFiltered: [],
      selectedUsersData: [],
      educationalGroupAttendance: new EducationalGroupAttendanceModel(),
      ATTENDANCE_TYPE,
      SUBSCRIPTION_TYPE_TOKENS,
      priceListTokenOptions: [],
      accountTokenOptions: [],
      paymentMethodTokenOptions: [],
      unknownIcon,
      attendanceIcon,
      absenceIcon,
      // ==============
      showTable: false,
    };
  },
  props: {
    educationalScheduleTime: { type: Object },
    usersData: { type: Array, default: () => [] },
    defaultImg: { type: String, default: "" },
  },
  computed: {
    hasDataSelected() {
      return (
        this.selectedUsersData.length > 0 &&
        checkPrivilege(hasEducationalGroupAttendanceAdd())
      );
    },
    hasDataFiltered() {
      return (
        this.usersDataFiltered.length > 0 &&
        checkPrivilege(hasEducationalGroupAttendanceAdd())
      );
    },
    searchFilteredUsersData() {
      const searchableKeys = ["fullCode"];
      return this.filteredUsersData.filter((item) => {
        if (this.filterTable == "") return true;

        return searchableKeys.some((key) => {
          return (
            item[key]
              .toString()
              .toLowerCase()
              .indexOf(this.filterTable.toLowerCase()) >= 0
          );
        });
      });
    },
  },
  watch: {
    usersData: function () {
      this.filterUsersDataSubscriptionType();
      this.getUsersDataFiltered();
      this.selectAll("attendance");
    },
    filterTable: function (v) {
      if (v && this.searchFilteredUsersData.length == 1) {
        let item = this.searchFilteredUsersData[0];
        let canAttendaceStatus = item.subscriptionData.canAttendaceStatus;
        let studentStatusText = item.studentStatusText;

        if (
          item.currentEducationalScheduleData.attendanceTypeToken ==
          ATTENDANCE_TYPE.Unknown
        ) {
          if (v.length == item.fullCode.length) {
            if (canAttendaceStatus) {
              let foundItem = this.selectedUsersData.find(
                (item) => item.fullCode === v
              );
              if (!foundItem) {
                this.selectedUsersData.push(item);
              }
              this.filterTable = "";
            } else {
              this.showMsg(studentStatusText);
            }
          }
        } else {
          this.showMsg(
            `${this.$t("ConstantsListSelect.AttendanceTypesName")}: ${
              item.currentEducationalScheduleData.attendanceTypeNameCurrent
            }`
          );
        }
      }
    },
  },
  created() {
    this.filterUsersDataSubscriptionType();
    this.getUsersDataFiltered();
    this.selectAll("attendance");
    this.scrollToSection();
  },
  methods: {
    // ========Added By Magdy=============
    scrollToSection() {
      // Use Vue's nextTick to wait for the DOM update
      this.$nextTick(() => {
        const elementToScrollTo = document.querySelector(
          "#section-to-scroll-to"
        );
        if (elementToScrollTo) {
          elementToScrollTo.scrollIntoView({ behavior: "smooth" });
        }
      });
    },

    // =====================
    getAttendanceIcon(token) {
      if (token == ATTENDANCE_TYPE.Unknown) {
        return this.unknownIcon;
      } else if (token == ATTENDANCE_TYPE.Attendance) {
        return this.attendanceIcon;
      } else if (token == ATTENDANCE_TYPE.Absence) {
        return this.absenceIcon;
      }
    },

    setSelectedSubscriptionDebtsNotFinishedData(user) {
      this.$emit("setSelectedSubscriptionDebtsNotFinishedData", {
        list: user.subscriptionDebtsNotFinishedData,
        userToken: user.userToken,
      });
    },
    setEducationalGroupUpdateWarningData(user) {
      this.$emit("setEducationalGroupUpdateWarningData", {
        ...user,
        educationalGroupStudentToken:
          user.educationalScheduleHalperTokens.educationalGroupStudentToken,
        educationalJoiningApplicationToken:
          user.educationalScheduleHalperTokens
            .educationalJoiningApplicationToken,
      });
    },
    setJoiningApplicationSubscriptionData(user) {
      // Create a new Date object from the UTC datetime string
      const utcDate = new Date(user.subscriptionData.subscriptionToDateTime);
      // Add one month to the date object
      utcDate.setUTCMonth(utcDate.getUTCMonth() + 1);
      // Convert the result back to a string in ISO format
      const resultUtcDateString = utcDate.toISOString();
      let subscriptionToDateTime = resultUtcDateString;

      this.$emit("setJoiningApplicationSubscriptionData", {
        ...user,
        subscriptionToDateTime: subscriptionToDateTime,
        subscriptionTypeToken: SUBSCRIPTION_TYPE_TOKENS.Duration,
        educationalJoiningApplicationToken:
          user.educationalScheduleHalperTokens
            .educationalJoiningApplicationToken,
      });
    },
    removeUserFromSelectedList(user) {
      this.selectedUsersData = this.selectedUsersData.filter(
        (item) => item.userToken != user.userToken
      );
    },
    filterUsersDataSubscriptionType() {
      this.filteredUsersData = this.usersData.filter(
        (user) =>
          user.subscriptionData.subscriptionTypeToken !=
          SUBSCRIPTION_TYPE_TOKENS.Hour
      );
    },
    getUsersDataFiltered() {
      this.usersDataFiltered = this.usersData.filter(
        (user) =>
          user.currentEducationalScheduleData.attendanceTypeToken ==
            ATTENDANCE_TYPE.Unknown &&
          user.subscriptionData.subscriptionTypeToken !=
            SUBSCRIPTION_TYPE_TOKENS.Hour
      );
    },
    selectAll(status = "") {
      this.filterTable = "";
      this.attendanceTokens = [];
      this.absenceTokens = [];
      switch (status) {
        case "attendance":
          this.attendanceSelectedAll = !this.attendanceSelectedAll;
          this.absenceSelectedAll = false;
          if (this.attendanceSelectedAll) {
            for (let user in this.usersDataFiltered) {
              this.attendanceTokens.push(
                this.usersDataFiltered[user].userToken
              );
            }
          }
          break;
        case "absence":
          this.absenceSelectedAll = !this.absenceSelectedAll;
          this.attendanceSelectedAll = false;
          if (this.absenceSelectedAll) {
            for (let user in this.usersDataFiltered) {
              this.absenceTokens.push(this.usersDataFiltered[user].userToken);
            }
          }
          break;
        default:
          break;
      }
    },
    select(status, value) {
      const indexAbsence = this.absenceTokens.indexOf(value);
      const indexAttendance = this.attendanceTokens.indexOf(value);

      switch (status) {
        case "attendance":
          if (indexAbsence > -1) {
            this.absenceTokens.splice(indexAbsence, 1);
          }

          setTimeout(() => {
            if (this.usersDataFiltered.length == this.attendanceTokens.length) {
              this.attendanceSelectedAll = true;
              this.absenceSelectedAll = false;
            } else {
              this.attendanceSelectedAll = false;
              this.absenceSelectedAll = false;
            }
          }, 1000);
          break;
        case "absence":
          if (indexAttendance > -1) {
            this.attendanceTokens.splice(indexAttendance, 1);
          }

          setTimeout(() => {
            if (this.usersDataFiltered.length == this.absenceTokens.length) {
              this.absenceSelectedAll = true;
              this.attendanceSelectedAll = false;
            } else {
              this.absenceSelectedAll = false;
              this.attendanceSelectedAll = false;
            }
          }, 1000);
          break;
        default:
          break;
      }
    },
    async addEducationalGroupAttendance() {
      this.educationalGroupAttendance.educationalGroupToken =
        this.educationalScheduleTime.educationalGroupToken;
      this.educationalGroupAttendance.educationalScheduleTimeToken =
        this.educationalScheduleTime.educationalScheduleTimeToken;
      this.educationalGroupAttendance.studentsList = [];
      let attendanceTokensSelected = [];
      let absenceTokensSelected = [];
      this.selectedUsersData.forEach((element) => {
        let attendanceTokenSelected = this.attendanceTokens.filter(
          (i) => i == element.userToken
        );
        if (attendanceTokenSelected.length)
          attendanceTokensSelected.push(attendanceTokenSelected[0]);

        let absenceTokenSelected = this.absenceTokens.filter(
          (i) => i == element.userToken
        );
        if (absenceTokenSelected.length)
          absenceTokensSelected.push(absenceTokenSelected[0]);
      });

      if (
        attendanceTokensSelected.length <= 0 &&
        absenceTokensSelected.length <= 0
      ) {
        this.showMsg(this.$t("students.pleaseSelectOne"));
        return;
      }

      if (attendanceTokensSelected.length > 0) {
        attendanceTokensSelected.forEach((element) => {
          let item = {
            userToken: element,
            attendanceTypeToken: ATTENDANCE_TYPE.Attendance,
            attendFromDateTime:
              this.educationalScheduleTime.dateTimeFromCustomized,
            attendToDateTime: this.educationalScheduleTime.dateTimeToCustomized,
          };
          this.educationalGroupAttendance.studentsList.push(item);
        });
      }
      if (absenceTokensSelected.length > 0) {
        absenceTokensSelected.forEach((element) => {
          let item = {
            userToken: element,
            attendanceTypeToken: ATTENDANCE_TYPE.Absence,
            attendFromDateTime:
              this.educationalScheduleTime.dateTimeFromCustomized,
            attendToDateTime: this.educationalScheduleTime.dateTimeToCustomized,
          };
          this.educationalGroupAttendance.studentsList.push(item);
        });
      }

      this.isLoading = true;
      try {
        let response = await apiEducationalGroupAttendance.add(
          this.educationalGroupAttendance
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },

    setEducationalScheduleUserData(item) {
      this.$emit("setEducationalScheduleUserData", item);
    },
    setEducationalGroupAttendanceData(user) {
      let obj = {
        educationalGroupAttendanceToken:
          user.currentEducationalScheduleData.educationalGroupAttendanceToken,
        userToken: user.userToken,
        attendanceTypeToken:
          user.currentEducationalScheduleData.attendanceTypeToken,
        attendFromDateTime:
          user.currentEducationalScheduleData.attendFromDateTime,
        attendToDateTime: user.currentEducationalScheduleData.attendToDateTime,

        userInfoData: {
          userNameCurrent: user.userNameCurrent,
        },
      };

      this.$emit("setEducationalGroupAttendanceData", obj);
    },
    checkPrivilege,
    isDataExist,
    formateDateTimeLang,
    hasEducationalScheduleTimeEdit,
    hasEducationalGroupAttendanceAdd,
    hasJoiningApplicationSubscriptionAdd,
    hasUpdateWarning,
    hasDebt,
    hasEducationalGroupAttendanceFinaleDelete,
  },
};
</script>

<style lang="scss" scoped>
.show-table-attend {
  cursor: pointer;
  background-color: var(--color-middel) !important;
}
</style>

